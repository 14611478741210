<template>
  <validation-provider :name="label" :rules="rules" v-slot="validationContext">
  <b-form-group>
    <b-form-radio-group
      :id="label"
      :name="label"
      :options="options"
      v-model="value"
      :stacked="stacked"
      @input="$emit('input',$event)"
      :disabled="disabled"
    >
    </b-form-radio-group>
  </b-form-group>
  <div v-if="validationContext.errors[0]" class="flex">
      <small class="text-danger">{{ validationContext.errors[0] }}</small>
    </div>
</validation-provider>
</template>

<script>
import { extend, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", required);

export default {
components: {
    ValidationProvider,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      require: true,
    },
    stacked: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      required:false
    },
    label:{
      required:false
    },
    disabled: {
      required: false,
      default: false
    }
  }
};
</script>

<style scoped>
.form-group {
  background-color: #fff;
  padding-bottom: 0;
  border-bottom: none;
  text-align: start;
}
.container {
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.container label {
  margin: 0px 10px;
}

input[type="radio"] {
  position: relative;
  cursor: pointer;
  margin-top: 3px;
  height: 0;
}
.custom-control-label, .custom-control-input  .custom-control-label{
  color:black!important
}
input[type="radio"]:before {
  background-color: white;
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 3px;
}

input[type="radio"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #cf0209;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 3px;
  left: 6px;
}
</style>
