<template>
<validation-provider :name="label" :rules="required" v-slot="validationContext">
  <b-form-group :label="label" :aria-describedby="required"  :class="{ disabled }">
    <b-dropdown  class="background__Dropdown w-full h-14 flex items-center justify-center  font-bold text-black "
                 :text="itemSelected == '' ? label : itemSelected"
                 :class="{ responsive : Items.length > 4}"
                 :disabled="disabled">
      <b-dropdown-item
        v-for="item in Items"
        :key="item.id ? item.id : item"
        v-model="value"
        @click="ItemSelecte(item)"
        :active="item.id == value &&  itemSelected == '' ? ItemSelecte(item) : false"
        :class="{ disabled }"
      >
        {{ item.descricao }}
      </b-dropdown-item>
    </b-dropdown>
  </b-form-group>
  <div v-if="validationContext.errors[0]" class="flex">
      <small class="text-danger">{{ validationContext.errors[0] }}</small>
    </div>
</validation-provider>
</template>

<script>
  import { ValidationProvider } from "vee-validate";
export default {

  name: "DropDown",
  props: {
    Items: {
      type: Array,
      required: true,
      },
      label: {
      type: String,
      required: false,
    },
      valueDefault: {
      type: String,
      required: false,
    },
    required: {
      type: String,
      required: false,
    },
    value: {
      required: true,
    },
    disabled: {
      type:Boolean,
      required:false,
    }
  },
  components: {
    ValidationProvider
  },
  data() {
    return {
      itemSelected: "",
    }
  },
  methods: {
    ItemSelecte (select) {
        this.itemSelected = select.descricao;
        this.$emit("ItemSelectId",select.id);
        this.$emit("item", select);
        this.$emit("SiglaUf",select.descricao);
        this.$emit("input", select.id);
      }
    },
    watch: {
      value(value,old){
        if (value == null || value == '' || value != old) this.itemSelected = '';
      }
    }
}
</script>

<style lang="scss" >
.background__Dropdown {
  background: #efefef;
  height: 1.5rem!important;

  .dropdown-toggle,
  .dropdown-item{
    border-radius:0!important;
    box-shadow: none;
    display: flex;
    justify-content: flex-start;
    padding-left: 0.75rem!important;
    background: #efefef;
    border: 0!important;
    width: 100%;
   }
   .dropdown-menu{
     border: 1px solid rgba(0, 0, 0, 0.15);
     border-radius: 0.25rem;
    }
  button{
    display: flex;
    align-items: center;
    background-color: #efefef!important;
    color: black!important;
    border: none;
    padding: 0%;
    padding-left: 0.75rem;
  }
   button:hover, button:focus, button:active, li:hover, li:focus, li:active, ul:hover, ul:focus, ul:active{
     background-color:#efefef!important;
    color: black!important;

   }
.form-control:disabled,
.disabled,
.disabled legend,
.disabled:hover{
  background-color: #cdcdcd!important;
}
}
.responsive .dropdown-menu{
     height: 144px;
     overflow-y: scroll;
   }
</style>
